import React, { useEffect, useRef, useState } from 'react';
import HeroSection from '../../components/HeroSection';
import HowItWorks from '../../components/How_it_Works/How_it_works';
import AnyKind from '../../components/AnyKind';
import Partner from '../../components/Partner/Partner';
import OurHappyPartners from '../../components/OurHappyPartners/OurHappyPartners';
import OnitFile from '../../components/OnitCon';
import SearchBox from '../../components/SearchBox';
import Footer from '../../components/Footer/Footer';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  SeriveContainer,
  HeroBg,
  ImgBg,
  ServiceContainer,
  ServiceTitle,
  ServiceDesc,
  SericeFormBox,
  SericeForm,
  ServiceFromTitle,
  ServiceFormRow,
  FormLabel,
  ServiceText,
  ServiceSearch,
  SearchIcon,
  AnyButton,
  AnyButtonText,
  InputBox,
  CoupanBox,
  InputFullBox,
  CoupanBoxRow,
  ErrorBox,
} from './ServiceElements';
import Input from '../../components/Input';
import { Formik, Field, Form } from 'formik';
import banLogo from '../../images/MaskGroup20.png';
// import AnyKind from '../../components/AnyKind';
// import OnitFile from '../../components/OnitCon';
// import Footer from '../../components/Footer/Footer';
import SelectBox from '../../components/Select';
import { withRouter, useHistory } from 'react-router-dom';
import {
  completeProfileValidator,
  completeProfileValidatorPickAndDrop,
} from '../../utils/validators';
import { get, isEmpty } from 'lodash';
import axios from '../../axios';
import { toast } from 'react-toastify';
import { IndianStates } from '../States';

import {
  API_URL,
  RAZORPAY_KEY,
  PAYMENT_NAME,
  PAYMENT_DESCRIPTION,
} from '../../constants/Statics';
import useRazorpay from 'react-razorpay';
import { DesktopDatePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
const Service = (props) => {
  const history = useHistory();
  const productDetails = props.location.state || {};
  const [indState, setIndState] = useState(IndianStates);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [Razorpay] = useRazorpay();
  const [personalDetails, setPersonalDetails] = useState({
    service: productDetails,
    name: '',
    whatspp_number: '',
    alternate_number: '',
    house_number: '',
    requirements: '',
    locality: '',
    city: '',
    state: '',
    country: 'India',
    pincode: '',
    time_preference: '',
    offer_code: 'ONIT2025',
    dropLocation: '',
    vehicles: [],
    offerPrice: null,
    pickLocation: '',
  });
  const [serviceYou, setServiceYou] = useState(productDetails);
  const coloroptions = [
    { value: 'IMMEDIATELY', label: 'IMMEDIATELY' },
    { value: 'WITHIN_24_HOURS', label: 'WITHIN 24 HOURS' },
    { value: 'SPECIFIC_DATE_AND_TIME', label: 'SPECIFIC_DATE_AND_TIME' },
  ];

  const [categoryList, setCategoryList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [otp, setOtp] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [pincode, setPincode] = useState('');
  const [userId, setUserId] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [platformCharge, setPlatformCharge] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [values, setValues] = useState({});
  const [value, setValue] = React.useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  console.log(personalDetails?.service?.value, 'personalDetails');

  const userIdRef = useRef();

  useEffect(() => {
    if (userId) {
      userIdRef.current = userId; // Keep the ref updated
    }
  }, [userId]);

  useEffect(() => {
    getAllVehicles();
  }, []);

  const getAllVehicles = async () => {
    try {
      const vehiclesData = await axios.get('/vehicle/getAll-vehicle');
      setVehicleList(
        vehiclesData?.data?.data?.map((el) => {
          return { label: el?.vehicleType, value: el?._id };
        })
      );
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };
  console.log(personalDetails?.vehicles, 'v');

  const fetchCategoryList = async () => {
    try {
      let { data } = await axios.get('/admin/get-all-active-services');

      setCategoryList(
        data.data.map((item) => {
          return { label: item.service_name, value: item._id };
        })
      );
    } catch (error) {}
  };

  const handleProfileSubmit = async (values) => {
    setLoading(true);
    let res1;
    console.log(values, 'xx');
    try {
      if (values.service.value === '64462802f77b1ff1d68890fd') {
        res1 = await axios.post('center/public-web-ticket-booking-paid', {
          service_provided_for: values.service.value,
          vehicleId: values.vehicles.value,
          personal_details: {
            primary_phone: {
              country_code: '+91',
              mobile_number: values.whatspp_number,
            },
            alternate_phone: {
              country_code: '+91',
              mobile_number: values.alternate_number,
            },
            name: values.name,
          },
          pickupAddress: {
            longitude: '',
            latitude: '',
            house_number: '',
            locality: '',
            city: '',
            state: '',
            short_code_for_place: '',
            pincode: pincode,
            pick_location: values.pickLocation, // combine
          },
          dropAddress: {
            longitude: '',
            latitude: '',
            house_number: '',
            locality: '',
            city: '',
            state: '',
            short_code_for_place: '',
            drop_location: values.dropLocation,
          },
          time_preference: {
            time_preference_type: values.time_preference,
            ...(values.time_preference === 'SPECIFIC_DATE_AND_TIME' && {
              specific_date_time: selectedDate,
            }),
          },
          offers_applied: {
            offer_code: values.offer_code,
          },
        });
        if (res1?.data?.data?.ticket_id) {
          setTicketId(res1?.data?.data?.ticket_id);
          setPlatformCharge(res1?.data?.data?.platform_charge);
          handleSendOtp(values);
        } else {
          setLoading(false);
        }
      } else {
        res1 = await axios.post('/center/public-web-ticket-booking-paid', {
          service_provided_for: values.service.value,
          specific_requirement: values.requirements,
          personal_details: {
            primary_phone: {
              country_code: '+91',
              mobile_number: values.whatspp_number,
            },
            alternate_phone: {
              country_code: '+91',
              mobile_number: values.alternate_number,
            },
            name: values.name,
          },
          time_preference: {
            time_preference_type: values.time_preference,
            ...(values.time_preference === 'SPECIFIC_DATE_AND_TIME' && {
              specific_date_time: selectedDate,
            }),
          },
          offers_applied: {
            offer_code: values.offer_code,
          },
          address_details: {
            house_number: values.house_number,
            locality: values.locality,
            city: values.city,
            state: values.state,
            pincode: values.pincode,
            country: values.country,
          },
        });
        if (res1?.data?.data?.ticket_id) {
          setTicketId(res1?.data?.data?.ticket_id);
          handleSendOtp(values);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log('Error', error);
      setLoading(false);

      // Check if the error response matches the specified structure
      if (
        error.response &&
        error.response.data &&
        error.response.data.message ===
          'Sorry, currently this service is not available in your pin code' &&
        error.response.data.status === 410 &&
        error.response.data.success === 0
      ) {
        setAlertMessage(
          'Sorry, The services requested are not yet active in your pincode'
        );
        setOpenAlert(true);
      }
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCheckUserExist = async (values) => {
    try {
      let res1 = await axios.post(
        '/consumerAppAppRoute/register-consumer-by-web',
        {
          personal_details: {
            phone: {
              country_code: '+91',
              mobile_number: phone,
            },
            name: name,
          },
          address_details_permanent: {
            pincode: pincode,
          },
        }
      );

      if (res1?.data?.status === 200) {
        // console.log(res1.data.data?.consumerDetails?._id, 'Transaction');
        setAccessToken(res1?.data?.data?.token);
        // setPhone(values.whatspp_number);
        setUserId(res1?.data?.data?.consumerDetails?._id);
        handleInitiatePayment(
          res1?.data?.data?.consumerDetails?._id,
          res1?.data?.data?.token
        );
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('Error', error);
      setLoading(false);
    }
  };

  // console.log(userId, 'Transaction');

  const handleSendOtp = async (values) => {
    try {
      // Start countdown before sending OTP
      setResendDisabled(true);
      setCountdown(60);

      const _data = {
        country_code: '+91',
        mobile_number:
          values?.whatspp_number === undefined ? phone : values?.whatspp_number,
        name: values?.name,
        address_details_permanent: {
          pincode: values?.pincode,
        },
      };
      const res2 = await axios({
        method: 'post',
        url: '/consumerAppAppRoute/sent-otp-by-web',
        data: _data,
      });
      setOtpSend(true);
      setValues(values);

      // Countdown from 30 to 1
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(interval);
            setResendDisabled(false); // Enable resend button after countdown finishes
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } catch (error) {
      console.log(error);
      setOtpSend(false);
    }
  };

  const handleVerifyOtp = async (values) => {
    values.preventDefault();

    try {
      const _data = {
        country_code: '+91',
        mobile_number: phone,
        otp: parseInt(otp),
      };
      const res2 = await axios({
        method: 'post',
        url: '/consumerAppAppRoute/verify-otp-by-web',

        data: _data,
      });
      console.log('========', { res2 });
      setOtpSend(false);
      handleCheckUserExist();
    } catch (error) {
      console.log(error);
      setOtpSend(true);
      alert('Wrong OTP');
    }
  };

  const handleInitiatePayment = async (id, token) => {
    try {
      await axios({
        method: 'get',
        url: `${API_URL}/center/get-razorpay-key`,
      }).then((res) => {
        const options = {
          key: res?.data?.data?.key_id,
          amount:
            personalDetails?.service?.value === '64462802f77b1ff1d68890fd'
              ? platformCharge * 100
              : 99 * 100,
          currency: 'INR',
          name: 'OnIt Services',
          description: 'Request Booking',
          image: 'https://onit.services/static/media/main_logo.406f2fdc.png',
          // order_id: ticketId,
          handler: (res1) => {
            if (
              personalDetails?.service?.value === '64462802f77b1ff1d68890fd'
            ) {
              if (res1.razorpay_payment_id.trim()) {
                handleUpdatePickDropStatus(
                  res1.razorpay_payment_id.trim(),
                  'success'
                );
              } else {
                handleUpdatePickDropStatus(
                  res1.razorpay_payment_id.trim(),
                  'failed'
                );
                setLoading(false);
              }
            } else {
              if (res1.razorpay_payment_id.trim()) {
                handleUpdateTicketStatus(
                  res1.razorpay_payment_id.trim(),
                  'success'
                );
              } else {
                handleUpdateTicketStatus(
                  res1.razorpay_payment_id.trim(),
                  'failed'
                );
                setLoading(false);
              }
            }
          },
          prefill: {
            name: ticketId,
            email: 'email.com',
            contact: phone,
          },
          // notes: {
          //   address: "Razorpay Corporate Office",
          // },
          theme: {
            color: '#3399cc',
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePickDropStatus = async (transactionId, status) => {
    const payload = {
      ticket_obj_id: ticketId,
      status: status, //[success, failed]
      amount: platformCharge,
      userId: userIdRef?.current,
      transactionId: transactionId,
    };
    try {
      const res = await axios({
        method: 'post',
        url: `/payment/capture-razorpay-payment`,
        data: payload,
      });
      console.log(res, 'responsepick');
      if (res?.data?.success === 1) {
        alert('Your request created successfully');
        history.push('/');
      }
    } catch (err) {
      alert('Payment failed! Your request not created');
    }
  };
  const handleUpdateTicketStatus = async (transactionId, status) => {
    // history.push("/");
    // const payload = {
    //   ticket_obj_id: ticketId,
    //   payment_status: status, //[success, failed]
    //   payment_amount: 99,
    // };
    console.log(userIdRef?.current, 'Transaction');
    const payload = {
      ticket_obj_id: ticketId,
      status: status, //[success, failed]
      amount: 99,
      userId: userIdRef?.current,
      transactionId: transactionId,
    };
    try {
      const res = await axios({
        method: 'post',
        url: `/payment/capture-payment-broadcastTicket`,
        data: payload,
      });
      console.log({ resafterPayment: res?.data?.success });
      if (res?.data?.success === 1) {
        alert('Your request created successfully');
        history.push('/');
      }
    } catch (err) {
      console.log({ err: err?.response?.data?.success });
      alert('Payment failed! Your request not created');
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  console.log(props, 'p[');

  return (
    <div>
      <Snackbar
        open={openAlert}
        autoHideDuration={60000} // Adjust duration as needed
        onClose={handleCloseAlert}
        TransitionComponent={SlideTransition} // Use custom transition
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position the Snackbar at the top center
      >
        <Alert severity="error" onClose={handleCloseAlert}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <SeriveContainer>
        <HeroBg>
          {productDetails.backgroundImg ? (
            <ImgBg src={productDetails.backgroundImg} />
          ) : (
            <ImgBg src={props.location.backgroundImg} />
          )}
        </HeroBg>
        <ServiceContainer>
          <ServiceTitle>Namaste !</ServiceTitle>
          <ServiceDesc>Please help us understand your need</ServiceDesc>
        </ServiceContainer>
      </SeriveContainer>
      <SericeFormBox>
        <SericeForm>
          <div
            style={{
              textAlign: 'center',
              fontSize: '15px',
              fontWeight: 'bold',
              marginBottom: '15px',
              backgroundColor: '#787170',
              color: 'white',
              padding: '10px', // Add padding for better visibility
            }}
          >
            *100% Cashback of Booking Amount in your Wallet(T&C Apply)
          </div>

          <ServiceFromTitle>Personal Details:</ServiceFromTitle>
          <Formik
            enableReinitialize
            initialValues={personalDetails}
            validate={
              personalDetails.service.value === '64462802f77b1ff1d68890fd'
                ? completeProfileValidatorPickAndDrop
                : completeProfileValidator
            }
            // onclick={displayRazorpay}
            onSubmit={handleProfileSubmit}
            validateOnChange
          >
            {(formikBag) => {
              const fetchPincode = async () => {
                try {
                  // Request user's location
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                      async (position) => {
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        // Fetch pincode using the coordinates
                        const response = await fetch(
                          `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
                        );

                        const data = await response.json();

                        if (data?.address?.postcode) {
                          const fetchedPincode = data.address.postcode;
                          formikBag.setFieldValue('pincode', fetchedPincode);
                          setPincode(fetchedPincode);
                        } else {
                          alert('Unable to fetch the pincode.');
                        }
                      },
                      (error) => {
                        console.error('Error getting user location:', error);
                        alert(
                          'Unable to get your location. Please enable location access.'
                        );
                      }
                    );
                  } else {
                    alert('Geolocation is not supported by your browser.');
                  }
                } catch (error) {
                  console.error('Error fetching pincode:', error);
                  alert('An error occurred while fetching the pincode.');
                }
              };

              console.log(pincode, 'pincode');

              return (
                <Form>
                  <ServiceFormRow>
                    <InputBox>
                      <FormLabel>
                        Service You Required
                        <span style={{ color: 'red', marginLeft: '2px' }}>
                          *
                        </span>
                      </FormLabel>
                      <Field name="service">
                        {({ field }) => (
                          <SelectBox
                            options={categoryList}
                            isSearchable={false}
                            isClearable={false}
                            defaultValue={formikBag.values.service}
                            // className="form-control"
                            // className={classes.colorDropdown}
                            className="cm-select"
                            placeholder="Select Service"
                            onChange={(option) => {
                              formikBag.setFieldValue('service', option);
                              //console.log(option.value)
                            }}
                            error={
                              formikBag.touched.service &&
                              formikBag.errors.service
                                ? formikBag.errors.service
                                : null
                            }
                          />
                        )}
                      </Field>
                    </InputBox>
                    {formikBag.values.service.value ===
                    '64462802f77b1ff1d68890fd' ? (
                      <InputBox>
                        <FormLabel>
                          Vehicles
                          <span style={{ color: 'red', marginLeft: '2px' }}>
                            *
                          </span>
                        </FormLabel>
                        <Field name="service">
                          {({ field }) => (
                            <SelectBox
                              options={vehicleList}
                              isSearchable={false}
                              isClearable={false}
                              defaultValue={formikBag.values.vehicles}
                              // className="form-control"
                              // className={classes.colorDropdown}
                              className="cm-select"
                              placeholder="Select Vehicle"
                              onChange={(option) => {
                                formikBag.setFieldValue('vehicles', option);
                                //console.log(option.value)
                              }}
                              error={
                                formikBag.touched.vehicles &&
                                formikBag.errors.vehicles
                                  ? formikBag.errors.vehicles
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </InputBox>
                    ) : null}
                    {formikBag.values.service.value !==
                      '64462802f77b1ff1d68890fd' && (
                      <InputBox>
                        <FormLabel>Specific Requirements - it helps</FormLabel>
                        <Field name="requirements">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.requirements}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'requirements',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.requirements &&
                                formikBag.errors.requirements
                                  ? formikBag.errors.requirements
                                  : null
                              }
                              className="form-control"
                              placeholder="Type your requirements"
                            />
                          )}
                        </Field>
                      </InputBox>
                    )}
                  </ServiceFormRow>
                  <ServiceFormRow>
                    {formikBag.values.service.value ===
                      '64462802f77b1ff1d68890fd' && (
                      <InputBox>
                        <FormLabel>Pick location</FormLabel>
                        <Field name="pickLocation">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.pickLocation}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'pickLocation',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.pickLocation &&
                                formikBag.errors.pickLocation
                                  ? formikBag.errors.pickLocation
                                  : null
                              }
                              className="form-control"
                              placeholder="Type your pick location"
                            />
                          )}
                        </Field>
                      </InputBox>
                    )}
                    {formikBag?.values?.service?.value ===
                      '64462802f77b1ff1d68890fd' && (
                      <InputBox>
                        <FormLabel>Drop location</FormLabel>
                        <Field name="dropLocation">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.dropLocation}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'dropLocation',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.dropLocation &&
                                formikBag.errors.dropLocation
                                  ? formikBag.errors.dropLocation
                                  : null
                              }
                              className="form-control"
                              placeholder="Type your drop location"
                            />
                          )}
                        </Field>
                      </InputBox>
                    )}
                  </ServiceFormRow>
                  <ServiceFormRow>
                    <InputBox>
                      <FormLabel>
                        Name (Contact Person)
                        <span style={{ color: 'red', marginLeft: '2px' }}>
                          *
                        </span>
                      </FormLabel>
                      <Field name="name">
                        {({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            value={formikBag.values.name}
                            noBorderBottom="none"
                            onChange={(e) => {
                              formikBag.setFieldValue('name', e.target.value);
                              setName(e.target.value);
                            }}
                            style={{
                              borderBottom: 'none',
                              border: '1px solid #CED4DA',
                              padding: '0 0.8rem',
                            }}
                            error={
                              formikBag.touched.name && formikBag.errors.name
                                ? formikBag.errors.name
                                : null
                            }
                            className="form-control"
                            placeholder="Enter Name"
                          />
                        )}
                      </Field>
                    </InputBox>
                    <InputBox>
                      <FormLabel>
                        Time Preference
                        <span style={{ color: 'red', marginLeft: '2px' }}>
                          *
                        </span>
                      </FormLabel>
                      <Field name="time_preference">
                        {({ field }) => (
                          <div>
                            <SelectBox
                              {...field}
                              options={
                                formikBag?.values?.service?.value ===
                                '64462802f77b1ff1d68890fd'
                                  ? coloroptions.filter(
                                      (el) => el.value !== 'WITHIN_24_HOURS'
                                    )
                                  : coloroptions
                              }
                              isSearchable={false}
                              isClearable={false}
                              className="cm-select"
                              style={{ padding: '0' }}
                              placeholder={
                                formikBag.values.time_preference ||
                                'Select time preference'
                              }
                              value={formikBag.values.time_preference} // Set value to display selected option
                              onChange={(option) => {
                                formikBag.setFieldValue(
                                  'time_preference',
                                  option.value
                                );
                              }}
                            />
                            {/* Conditionally render DatePicker based on time_preference */}
                            {formikBag.values.time_preference ===
                              'SPECIFIC_DATE_AND_TIME' && (
                              <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                showTimeSelect
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                style={{
                                  border: '1px solid #ccc',
                                  borderRadius: '4px',
                                  padding: '10px',
                                  width: '20px', // Adjust width as needed
                                  height: '20px', // Adjust height as needed
                                  fontSize: '16px', // Adjust font size as needed
                                  color: '#333', // Adjust text color as needed
                                  // Add more styles as needed
                                }}
                                popperModifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    escapeWithReference: false,
                                    boundariesElement: 'viewport',
                                  },
                                  hide: {
                                    enabled: false,
                                  },
                                  offset: {
                                    enabled: true,
                                    offset: '-20px, 0', // Adjust offset as needed
                                  },
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Field>
                    </InputBox>
                  </ServiceFormRow>
                  <ServiceFormRow>
                    <InputBox>
                      <FormLabel>
                        WhatsApp Number
                        <span style={{ color: 'red', marginLeft: '2px' }}>
                          *
                        </span>
                      </FormLabel>
                      <Field name="whatspp_number">
                        {({ field }) => (
                          <Input
                            {...field}
                            type="text" // Change input type to text
                            value={formikBag.values.whatspp_number}
                            noBorderBottom="none"
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                'whatspp_number',
                                e.target.value
                              );
                              setPhone(e.target.value);
                            }}
                            onWheel={(e) => e.preventDefault()} // Prevent scrolling from changing the value
                            style={{
                              borderBottom: 'none',
                              border: '1px solid #CED4DA',
                              padding: '0 0.8rem',
                              width: '100%',
                            }}
                            error={
                              formikBag.touched.whatspp_number &&
                              formikBag.errors.whatspp_number
                                ? formikBag.errors.whatspp_number
                                : null
                            }
                            className="form-control"
                            placeholder="Enter Phone Number"
                          />
                        )}
                      </Field>
                    </InputBox>
                    {formikBag?.values?.service?.value !==
                      '64462802f77b1ff1d68890fd' && (
                      <InputBox>
                        <FormLabel>Alternative Phone Number</FormLabel>
                        <Field name="alternate_number">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="number"
                              value={formikBag.values.alternate_number}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'alternate_number',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                              }}
                              error={
                                formikBag.touched.alternate_number &&
                                formikBag.errors.alternate_number
                                  ? formikBag.errors.alternate_number
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Phone Number"
                            />
                          )}
                        </Field>
                      </InputBox>
                    )}
                  </ServiceFormRow>

                  <ServiceFromTitle style={{ marginTop: '3rem' }}>
                    Address Details:
                  </ServiceFromTitle>
                  {formikBag?.values?.service?.value !==
                    '64462802f77b1ff1d68890fd' && (
                    <ServiceFormRow>
                      <InputFullBox>
                        <FormLabel>
                          House Number / Street
                          <span style={{ color: 'red', marginLeft: '2px' }}>
                            *
                          </span>
                        </FormLabel>
                        <Field name="house_number">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.house_number}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'house_number',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.house_number &&
                                formikBag.errors.house_number
                                  ? formikBag.errors.house_number
                                  : null
                              }
                              className="form-control"
                              placeholder="Please provide House Number / Street."
                            />
                          )}
                        </Field>
                      </InputFullBox>
                    </ServiceFormRow>
                  )}
                  <ServiceFormRow>
                    {formikBag?.values?.service?.value !==
                      '64462802f77b1ff1d68890fd' && (
                      <InputBox>
                        <FormLabel>
                          Locality
                          <span style={{ color: 'red', marginLeft: '2px' }}>
                            *
                          </span>
                        </FormLabel>
                        <Field name="locality">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.locality}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'locality',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.locality &&
                                formikBag.errors.locality
                                  ? formikBag.errors.locality
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Locality"
                            />
                          )}
                        </Field>
                      </InputBox>
                    )}
                    <InputBox style={{ position: 'relative' }}>
                      <FormLabel>
                        Pincode
                        <span style={{ color: 'red', marginLeft: '2px' }}>
                          *
                        </span>
                      </FormLabel>
                      <Field name="pincode">
                        {({ field }) => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.pincode}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'pincode',
                                  e.target.value
                                );
                                setPincode(e.target.value);
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '120%',
                              }}
                              error={
                                formikBag.touched.pincode &&
                                formikBag.errors.pincode
                                  ? formikBag.errors.pincode
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Pincode"
                            />
                            <button
                              type="button"
                              onClick={fetchPincode}
                              style={{
                                position: 'absolute',
                                right: '0.5rem',
                                height: '100%',
                                border: 'none',
                                backgroundColor: '#007BFF',
                                color: '#FFF',
                                padding: '0 8px',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                width: '30%',
                              }}
                            >
                              Fetch
                            </button>
                          </div>
                        )}
                      </Field>
                    </InputBox>
                    {formikBag?.values?.service?.value ===
                      '64462802f77b1ff1d68890fd' && (
                      <InputBox>
                        <FormLabel>
                          Offer price
                          <span style={{ color: 'red', marginLeft: '2px' }}>
                            *
                          </span>
                        </FormLabel>
                        <Field name="offerPrice">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.offerPrice}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'offerPrice',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.offerPrice &&
                                formikBag.errors.offerPrice
                                  ? formikBag.errors.offerPrice
                                  : null
                              }
                              className="form-control"
                              placeholder="Please provide offer price"
                            />
                          )}
                        </Field>
                      </InputBox>
                    )}
                  </ServiceFormRow>
                  {formikBag?.values?.service?.value !==
                    '64462802f77b1ff1d68890fd' && (
                    <ServiceFormRow>
                      <InputBox>
                        <FormLabel>
                          City
                          <span style={{ color: 'red', marginLeft: '2px' }}>
                            *
                          </span>
                        </FormLabel>
                        <Field name="city">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.city}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue('city', e.target.value);
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                              }}
                              error={
                                formikBag.touched.city && formikBag.errors.city
                                  ? formikBag.errors.city
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter City"
                            />
                          )}
                        </Field>
                      </InputBox>
                      <InputBox>
                        <FormLabel>
                          State
                          <span style={{ color: 'red', marginLeft: '2px' }}>
                            *
                          </span>
                        </FormLabel>
                        <Field name="state">
                          {({ field }) => (
                            <SelectBox
                              options={indState}
                              isSearchable={false}
                              isClearable={false}
                              defaultValue={formikBag.values.state}
                              // defaultValue=
                              // className="form-control"
                              // className={classes.colorDropdown}
                              className="cm-select"
                              style={{ padding: '0' }}
                              placeholder="Select State"
                              onChange={(option) => {
                                formikBag.setFieldValue('state', option.value);
                                // console.log(option.value)
                              }}
                              error={
                                formikBag.touched.state &&
                                formikBag.errors.state
                                  ? formikBag.errors.state
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </InputBox>
                    </ServiceFormRow>
                  )}
                  {formikBag?.values?.service?.value !==
                    '64462802f77b1ff1d68890fd' && (
                    <ServiceFormRow>
                      <InputBox>
                        <FormLabel>Country</FormLabel>
                        <Field name="country">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={formikBag.values.country}
                              noBorderBottom="none"
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  'country',
                                  e.target.value
                                );
                              }}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                              }}
                              error={
                                formikBag.touched.country &&
                                formikBag.errors.country
                                  ? formikBag.errors.country
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter Country"
                            />
                          )}
                        </Field>
                      </InputBox>
                      <CoupanBoxRow>
                        <CoupanBox>
                          <ServiceText>
                            <SearchIcon src={banLogo} />
                          </ServiceText>
                          <ServiceSearch>
                            <Field name="offer_code">
                              {({ field }) => (
                                <Input
                                  {...field}
                                  type="text"
                                  value={formikBag.values.offer_code}
                                  noBorderBottom="none"
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      'offer_code',
                                      e.target.value
                                    );
                                  }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    outline: 'none',
                                  }}
                                  className="form-control"
                                  placeholder="Offer Code"
                                />
                              )}
                            </Field>
                          </ServiceSearch>
                        </CoupanBox>
                        <ErrorBox>
                          {formikBag.touched.offer_code &&
                            formikBag.errors.offer_code && (
                              <p
                                style={{
                                  paddingTop: 5,
                                  fontSize: 13,
                                  color: 'red',
                                }}
                              >
                                {formikBag.errors.offer_code}
                              </p>
                            )}
                        </ErrorBox>
                      </CoupanBoxRow>
                    </ServiceFormRow>
                  )}

                  {otpSend === true ? (
                    <ServiceFormRow>
                      <InputBox>
                        <FormLabel>OTP</FormLabel>
                        <Field name="otp">
                          {({ field }) => (
                            <Input
                              {...field}
                              type="text"
                              value={otp}
                              noBorderBottom="none"
                              onChange={(e) => setOtp(e.target.value)}
                              style={{
                                borderBottom: 'none',
                                border: '1px solid #CED4DA',
                                padding: '0 0.8rem',
                                width: '100%',
                              }}
                              error={
                                formikBag.touched.pincode &&
                                formikBag.errors.pincode
                                  ? formikBag.errors.pincode
                                  : null
                              }
                              className="form-control"
                              placeholder="Enter OTP"
                            />
                          )}
                        </Field>
                      </InputBox>
                      <InputBox>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {resendDisabled && ( // Only show countdown when resend is disabled
                            <div style={{ marginRight: '5px' }}>
                              Resend OTP in ({countdown}s)
                            </div>
                          )}
                          <AnyButtonText
                            onClick={handleSendOtp}
                            style={{
                              marginBlock: 5,
                              padding: 5,
                              fontSize: 10,
                              width: '20%',
                              marginLeft: 'auto',

                              backgroundColor: 'blue',
                            }}
                            disabled={resendDisabled} // Disable button when resend is disabled
                          >
                            Resend OTP
                          </AnyButtonText>
                        </div>

                        <AnyButtonText onClick={handleVerifyOtp}>
                          Verify OTP
                        </AnyButtonText>
                      </InputBox>
                    </ServiceFormRow>
                  ) : null}

                  <ServiceFormRow style={{ justifyContent: 'center' }}>
                    {!loading && ( // Render button only if loading is false
                      <InputBox>
                        <AnyButtonText
                          type="submit"
                          onClick={handleProfileSubmit}
                        >
                          {`Pay ${
                            formikBag?.values?.service?.value ===
                            '64462802f77b1ff1d68890fd'
                              ? platformCharge || ''
                              : `₹99`
                          } to confirm booking`}
                        </AnyButtonText>
                      </InputBox>
                    )}
                  </ServiceFormRow>

                  {errorMessage && (
                    <p style={{ color: 'red', textAlign: 'center' }}>
                      {errorMessage}
                    </p>
                  )}
                </Form>
              );
            }}
          </Formik>
          <h4>{paymentStatus}</h4>
        </SericeForm>
      </SericeFormBox>
      <Footer />
      {/* <AnyKind /> */}
    </div>
  );
};

export default withRouter(Service);
