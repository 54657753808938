import React, { useState, useEffect } from 'react';
import HomeBg from '../../images/homeBg.png';
import kitchenIcon from '../../images/Group62665.png';
import pulumberIcon from '../../images/NoPath-Copy(19).png';
import airConIcon from '../../images/Group62664.png';
import carPaIcon from '../../images/NoPath-Copy(21).png';
import elecIcon from '../../images/NoPath-Copy(2).png';
import appliIcon from '../../images/NoPath-Copy(17).png';
import digiIcon from '../../images/NoPath-Copy(20).png';
import playIcon from '../../images/NoPath-Copy(25).png';
import dustIcon from '../../images/Group_62708.png';
import rickshaw from '../../images/rickshaw.png';
import ac from '../../images/13-SM636852.png';
import ca from '../../images/19-SM828398.png';
import ki from '../../images/chimney.jpeg';
import pl from '../../images/Group631.jpeg';
import el from '../../images/elec.jpeg';
import cl from '../../images/clean.avif';
import fr from '../../images/frrr.png';
import di from '../../images/di.webp';

import {
  HeroContainer,
  HeroBg,
  ImgBg,
  HeroContent,
  HeroIconContainer,
  HeroIconsRows,
  HeroIconRows,
  HeroIconBox,
  IconBox,
  IconImg,
  IconText,
  HerIconContainer,
  HeroPlayBox,
  PlayBox,
  PlayImg,
  PlayText,
  HeroService,
  ServiceView,
  HeroIconDoubleContainer,
  SearchButtonBox,
  Searchfeild,
  SearchIcon,
  HerIconDoubleContainer,
  SearchButtonDoubleBox,
  FlexCenter,
  ArrowPosition,
  SelectDrop,
} from './HeroElements';
import { Button } from '../ButtonElements';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { withRouter } from 'react-router-dom';
import axios from '../../axios';
import { useHistory } from 'react-router-dom';
import SelectBox from '../../components/SelectSearch';
const HeroSection = ({ defaultState, setDefaultState }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  let history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  const [serviceId, setServiceId] = useState('');

  const data = [
    {
      label: 'KITCHEN APPLIANCE - WATER PURIFIER, CHIMNEY ETC',
      value: '637b788c7c7cd9e139b39d09',
      image: kitchenIcon,
      bg: ki,
    },
    {
      label: 'AIR CONDITIONER - DOMESTIC (WINDOW AND SPLIT AC)',
      value: '6373436f1307e26d44ac8cdb',
      image: kitchenIcon,
      bg: ac,
    },
    {
      label:
        'PLUMBING - SHOWER, TAP, MIXER, CISTERN AND SEAT, OVERHEAD TANK FITTING ETC',
      value: '637b7a0e7c7cd9e139b39d1e',
      image: kitchenIcon,
      bg: pl,
    },
    {
      label:
        'ELECTRICIAN - FAN, TUBE, INVERTOR, HOUSE WIRING, MIXER, TOSTER, GRIDER ETC',
      value: '637b76f47c7cd9e139b39d02',
      image: kitchenIcon,
      bg: el,
    },
    {
      label: 'DIGITAL DEVICES - CCTV, LAPTOP, PRINTER, MOBILES ETC',
      value: '65dd7f2c181db7734209e2a7',
      image: kitchenIcon,
      bg: di,
    },
    {
      label: 'PAINT AND WHITEWASH - WHITEWASH / TEXTURE PAINT',
      value: '62641d3748f55f83cd6bb7a5',
      image: kitchenIcon,
      bg: cl,
    },
    {
      label: 'CARPENTER - ASSEMBLY/FITTINGS, DOOR/WINDOW, FURNITURE REPAIR ETC',
      value: '6455f89054106f153e47142f',
      image: kitchenIcon,
      bg: ca,
    },
    {
      label: 'HOSPITALITY SERVICES - CLEANING, SWEEPING, DUSTING, UTENSILS ETC',
      value: '637b79cd7c7cd9e139b39d17',
      image: kitchenIcon,
      bg: cl,
    },
    {
      label: 'HOME APPLIANCE - WASHING MACHINE, REFRIGERATOR, MWO, TV ETC',
      value: '6455f90254106f153e471437',
      image: kitchenIcon,
      bg: fr,
    },
    {
      label: 'DRIVER (CAR, BIKE) - PICKUP AND DROP SERVICE',
      value: '64462802f77b1ff1d68890fd',
      image: kitchenIcon,
      bg: '',
    },
  ];

  const fetchCategoryList = async () => {
    try {
      let { data } = await axios.get('/admin/get-all-active-services');
      console.log(data.data, 'DRIVER (CAR, BIKE) - PICKUP AND DROP SERVICE');
      setCategoryList(
        data.data.map((item) => {
          return { label: item.service_name, value: item._id };
        })
      );
    } catch (error) {
      // console.log(error);
      // toast.error("Something went wrong.", {
      //     position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);
  return (
    <HeroContainer>
      <HeroBg>
        <ImgBg />
      </HeroBg>
      <HeroContent>
        <HerIconDoubleContainer>
          <HeroPlayBox>
            <SearchButtonDoubleBox>
              {/* <Searchfeild type="text" placeholder="Search Services" /> */}
              <SelectDrop>
                <SelectBox
                  options={categoryList}
                  className="select-search"
                  placeholder="Search Services or Click on icons"
                  onChange={(e) => {
                    console.log(e);
                    setServiceId(e);
                  }}
                />
              </SelectDrop>
              <ArrowPosition>
                <SearchIcon
                  className="ph-arrow-right-bold"
                  onClick={() => {
                    history.push({
                      pathname: serviceId ? '/service-form' : '',
                      state: serviceId,
                    });
                  }}
                ></SearchIcon>
              </ArrowPosition>
            </SearchButtonDoubleBox>
          </HeroPlayBox>
        </HerIconDoubleContainer>
        <HeroIconContainer>
          <HeroIconRows>
            {/* <IconBox
              onClick={() =>
                history.push({
                  pathname: "/service-form",
                  state: { ...data[1], backgroundImg: ac },
                })
              }
            >
              <IconImg src={airConIcon} />
              <IconText>AC services</IconText>
            </IconBox> */}

            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-type',
                  state: { ...data[1], backgroundImg: ac },
                })
              }
            >
              <IconImg src={airConIcon} />
              <IconText>AC services</IconText>
            </IconBox>

            <IconBox
              style={{ marginTop: '-6rem' }}
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[6], backgroundImg: ca },
                })
              }
            >
              <IconImg src={carPaIcon} />
              <IconText>Carpenter</IconText>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[3], backgroundImg: el },
                })
              }
            >
              <IconImg src={elecIcon} />
              <IconText>Electrician</IconText>
            </IconBox>
          </HeroIconRows>
          <HeroIconsRows>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[0], backgroundImg: ki },
                })
              }
            >
              <IconImg src={kitchenIcon} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconText>Kitchen appliances</IconText>
              </div>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[9], backgroundImg: pl },
                })
              }
            >
              <IconImg
                src={rickshaw}
                style={{
                  background: '#ffffff',
                  width: '5rem',
                  height: '5rem',
                  padding: '0.5rem',
                  borderRadius: '100rem',
                }}
              />
              <IconText>Pick and drop</IconText>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[3], backgroundImg: pl },
                })
              }
            >
              <IconImg src={pulumberIcon} />
              <IconText>Plumber</IconText>
            </IconBox>
          </HeroIconsRows>
          <HeroIconRows style={{ marginTop: '-5.3rem' }}>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[8], backgroundImg: fr },
                })
              }
            >
              <IconImg src={appliIcon} />
              <IconText>Home Appliances</IconText>
            </IconBox>
            <IconBox
              style={{ marginTop: '8rem' }}
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[4], backgroundImg: di },
                })
              }
            >
              <IconImg src={digiIcon} />
              <IconText>Digital Devices</IconText>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[7], backgroundImg: cl },
                })
              }
            >
              <IconImg src={dustIcon} />
              <IconText>Cleaning</IconText>
            </IconBox>
          </HeroIconRows>
        </HeroIconContainer>

        <HeroIconDoubleContainer>
          <HeroIconRows>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: { ...data[9], backgroundImg: pl },
                })
              }
            >
              <IconImg
                src={rickshaw}
                style={{
                  background: '#ffffff',
                  width: '6rem',
                  height: '6rem',
                  padding: '0.5rem',
                  borderRadius: '100rem',
                }}
              />
              <IconText>Pick and drop</IconText>
            </IconBox>
          </HeroIconRows>
          <HeroIconRows>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[1],
                })
              }
            >
              <IconImg src={airConIcon} />
              <IconText>AC services</IconText>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[6],
                })
              }
            >
              <IconImg src={carPaIcon} />
              <div>
                <IconText>Carpenter</IconText>
              </div>
            </IconBox>
          </HeroIconRows>
          <HeroIconRows>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[0],
                })
              }
            >
              <IconImg src={kitchenIcon} />
              <div
                style={{
                  display: 'flex',
                  width: '131%',
                  justifyContent: 'center',
                }}
              >
                <IconText>Kitchen appliances</IconText>
              </div>
            </IconBox>

            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[2],
                })
              }
            >
              <IconImg src={pulumberIcon} />
              <IconText>Plumber</IconText>
            </IconBox>
          </HeroIconRows>
          <HeroIconRows>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[8],
                })
              }
            >
              <IconImg src={appliIcon} />
              <IconText>Appliances</IconText>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[4],
                })
              }
            >
              <IconImg src={digiIcon} />
              <div
                style={{
                  display: 'flex',
                  width: '131%',
                  justifyContent: 'center',
                }}
              >
                <IconText>Digital Devices</IconText>
              </div>
            </IconBox>
          </HeroIconRows>
          <HeroIconRows>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[7],
                })
              }
            >
              <IconImg src={dustIcon} />
              <IconText>Cleaning</IconText>
            </IconBox>
            <IconBox
              onClick={() =>
                history.push({
                  pathname: '/service-form',
                  state: data[3],
                })
              }
            >
              <IconImg src={elecIcon} />
              <IconText>Electrician</IconText>
            </IconBox>
          </HeroIconRows>
        </HeroIconDoubleContainer>
        <HerIconContainer>
          <HeroPlayBox>
            <SearchButtonBox>
              {/* <Searchfeild type="text" placeholder="Search Services" /> */}
              <SelectDrop>
                <SelectBox
                  options={categoryList}
                  className="select-search"
                  placeholder="Search Services or Click on icons"
                  onChange={(e) => {
                    console.log(e);
                    setServiceId(e);
                  }}
                />
              </SelectDrop>
              <ArrowPosition>
                <SearchIcon
                  className="ph-arrow-right-bold"
                  onClick={() => {
                    console.log(
                      data?.filter((el) => el?.value === serviceId?.value)[0]
                        ?.bg,
                      'serviceId'
                    );
                    history.push({
                      pathname: serviceId ? '/service-form' : '',
                      state: serviceId,
                      backgroundImg: data?.filter(
                        (el) => el?.value == serviceId?.value
                      )[0]?.bg,
                    });
                  }}
                ></SearchIcon>
              </ArrowPosition>
            </SearchButtonBox>
            <FlexCenter>
              <a href="https://play.google.com/store/apps/details?id=com.onit.consumer">
                <PlayImg src={playIcon} />
              </a>
            </FlexCenter>
          </HeroPlayBox>
        </HerIconContainer>
      </HeroContent>
    </HeroContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeroSection));
