import react from 'react';
import {
  SeriveContainer,
  HeroBg,
  ImgBg,
  ServiceContainer,
  ServiceTitle,
  ServiceDesc,
  BackImgBg,
  AboutTitle,
  AboutDesc,
  ContOne,
  Conttwo,
  ContImage,
  ContContainer,
  ExtraCont,
} from './AboutElements';
import OnitFile from '../../components/OnitCon';
import AnyKind from '../../components/AnyKind';
import Footer from '../../components/Footer/Footer';
import Contimg from '../../images/Onit_Images/MicrosoftTeams-image.png';

const About = (props) => {
  return (
    <div>
      <SeriveContainer>
        <HeroBg>
          <ImgBg />
        </HeroBg>
        <ServiceContainer>
          <ServiceTitle>About Us</ServiceTitle>
          <ServiceDesc>Want to know more about us.</ServiceDesc>
        </ServiceContainer>
      </SeriveContainer>
      <BackImgBg>
        <ContContainer>
          <ContOne>
            <ContImage src={Contimg} />
          </ContOne>
          <Conttwo>
            <AboutTitle>
              About <span style={{ color: '#00796A' }}>us</span>
            </AboutTitle>

            <AboutDesc>
              This journey was started in 2022 and today, ADA Enterprises is
              working in 17 states with more than 3000 professionals spread
              across 600 pincodes and growing.
            </AboutDesc>
            <AboutDesc>
              OniT Services is your one-stop solution for a wide range of
              services designed to simplify your life. Whether you need
              pick-and-drop assistance or home repair and maintenance, OniT
              Services has you covered. With just a few taps, you can access
              reliable, efficient, and convenient services—all at your
              fingertips. At OniT, we’re committed to making everyday tasks
              effortless for the home makers, so you can focus on what truly
              matters.
            </AboutDesc>
          </Conttwo>
        </ContContainer>
        <ExtraCont>
          <AboutDesc>
            ADA is recognized under the MSME (Micro, Small, and Medium
            Enterprises) category of the Government of India. It has established
            itself in the consumer market as an organization dedicated to making
            services affordable, reliable, and safe for customers, society, and
            service providers. Through the power of technology and a commitment
            to data integrity, ADA continues to set benchmarks in service
            excellence.
          </AboutDesc>
          {/* <AboutDesc>
                        Learn more on: <span style={{ color: "#0080FF", padding: "auto" }}>https://www.affordabledeals.group/</span>
                    </AboutDesc> */}
        </ExtraCont>
      </BackImgBg>
      <AnyKind style={{ marginTop: '0' }} />
      <OnitFile />
      <Footer />
    </div>
  );
};

export default About;
